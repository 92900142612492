import React, { PureComponent } from 'react';
import * as portActions from '../../actions/port';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './PublicPort.scss';
import p from '../Common/PortLinkNew.scss';
import mp from '../Common/MapPosition.scss';
import Header2 from '../Header/Header2';
import Link from '../Link';
import cx from 'classnames';
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import ActionCheckCircle from 'material-ui/svg-icons/action/check-circle';
import ContentBlock from 'material-ui/svg-icons/content/block';
import { ALL_WAREHOUSES, availableFacilities, restrictionsService } from '../Port/settings';
import { connect } from 'react-redux';
import { openSubscribeDialog } from '../../actions/login';
import { dateRange, number } from '../../core/format';
import Collapse from '../Common/Collapse';
import SearchInput from '../Common/SearchInput';
import Header from '../Header';
import PortWeather from './PortWeather';
import history from '../../core/history';
import Port from '../../core/api/Port';
import VesselsListTab from './VesselsListTab';
import TopNotifications from '../Common/TopNotifications';
import PortHeader from '../Port/PortHeader';
import EditPort from '../Port/EditPort';
import Eddie from '../Calculator/v2/Eddie';
import MainPageHero from '../Home/MainPageHero';
import { isUserOnFreePlan, somePropIsTrue } from '../../core/utils';
import withPortDetails from '../Port/withPortDetails';
import PortTerminals from '../Port/Terminals/PortTerminals';
import RaisedButton from '../Common/RaisedButton';
import VesselPreviewNew from '../Vessel/Preview/VesselPreviewNew';
import DialogHalfScreen from "../Common/DialogHalfScreen";
import PropTypes from 'prop-types';
import PubSub from "pubsub-js";
import ContactDetailsPopover from "../ContactBook/ContactDetailsPopover";
import CustomTooltip from '../Common/CustomTooltip';
import Page from '../Page/Page';
import OpenInNewTabButton from '../Common/OpenInNewTabButton';


const FACILITIES = availableFacilities[0].concat(availableFacilities[1].concat(availableFacilities[2]));

const hardCodeActivities = [
  "Bunker Trader",
  "Classification Society & Flag State representative",
  "Marine equipment & spare parts",
  "P & I","Pilotage",
  "Port Agency",
  "Port Authority",
  "Port Operator",
  "Repairer & Shipbuilder",
  "Salvage & Rescue",
  "Ship Chandler",
  "Stevedoring Company",
  "Survey Company",
  "Towage",
];

function getCranesArray(port) {
  let  cranesDetails = []
  if (port.liftsCranes) {
    for (const key in port.liftsCranes) {
      if (port.liftsCranes[key].checked && port.liftsCranes[key].details) {
        cranesDetails = cranesDetails.concat(port.liftsCranes[key].details);
      }
    }
  }
  cranesDetails.sort((a, b) => a.value - b.value);
  return cranesDetails;
}

class PublicPort extends PureComponent {
  constructor(props) {
    super(props);
    this.Api = Port;
    const port = props.port;

    this.state = {
      port,
      scrolling: false,
      direction: '',
      lastScrollPos: 0,
      toggledTab: 'vesselsInPort',
      openedPortList: false,
      portList: [],
      page: 1,
      filters: {}
    };
  }

  static propTypes = {
    port: PropTypes.object.isRequired,
    user: PropTypes.object,
    portId : PropTypes.string,
  };

  static defaultProps = {
    baseUrl: '/port/',
  }


  componentDidMount() {
    let { vesselsNearPort, vesselsDirectingToPort } = this.props.port;
    this.setState({
      toggledTab: vesselsNearPort?.length ? 'vesselsInPort' : vesselsDirectingToPort?.length ? 'plannedVessels' : 'vesselsInPort',
    });
  }

  componentWillUnmount() {
    this.setState({portList: []})
  }



  handleToggleTab = (str) => {
    this.setState({
      toggledTab: str,
    });
  };

  handleVesselPositionClick = (selectedVessel) => {
    this.portTerminals.triggerVesselMarker(selectedVessel);
  }
  handleVesselClose = () => {
    history.push(`/port/${this.state.port?.seo?.sefName || this.state.port?._id}`, undefined, undefined, {saveSearchParams: true});
  }

  refTerminals = (el) => {
    this.portTerminals = el;
  }

  render() {
    const port = this.state.port;

    const {eddie} = this.props;
    let portSite = '';
    if (port.site) {
      portSite = port.site.includes('http' || 'https') ? port.site : "//" + port.site;
    }
    const portRouteName = port?.seo?.sefName || port?._id;
    let { vesselsNearPort, vesselsDirectingToPort } = this.props.port;
    let VESSELS = this.state.toggledTab === 'vesselsInPort' ? vesselsNearPort : vesselsDirectingToPort;

    let generateLinkElement = (url, text) => {
      if (this.props.user && this.props.user._id) {
          return <a rel="nofollow" title={text} href={url} >{text} </a>;
      } else {
        return <span
                className={s.span_to_href}
                title={text}
                onClick={() => history.push("/login")}
              >
                {text}
              </span>;
      }
    };
    return (
      <Page headerTitle={'PORTS'}>
          {/* {!this.props.user ?
            <Header
              scroll={this.state.direction}
              label={this.state.label}
              main
              port
            />
            :
            <Header2
              scroll
              login
              title={'PORTS'}
            />
          }

          {this.props.user ?
            <TopNotifications/>
            : null
          }

          {!this.props.user ?
            <div className={cx(s.more_info, s.container_home)}>
              <MainPageHero />
            </div>
          : null
          } */}
          <div className={cx(s.port_container, "page_content")}>
            <div className={s.port_container_left}>
              <PortInfoSection port={port} editPort={this.props.editPort} user={this.props.user} baseUrl={this.props.baseUrl}/>
            </div>
            <div className={s.port_container_right}>
              {this.props.editPort && this.props.user
                ?
                <EditPort portId={port._id} portRouteName={portRouteName} baseUrl={this.props.baseUrl}/>
                :
                <div style={{height: '100%'}}>
                  <PortHeader
                    title="Status"
                  >
                  </PortHeader>
                  <div className={s.port_container_body}>
                    <PortTerminals ref={this.refTerminals} port={this.props.port} />
                    {this.props.port.weather ? <PortWeather weather={this.props.port.weather}/> : null}
                    <div className={cx(s.wrapper_table, s.wrapper_tab_vessels)}>
                      <div className={s.wrapper_title_tab}>
                        <p
                          className={this.state.toggledTab === 'vesselsInPort' ? s.active : null}
                          onClick={this.handleToggleTab.bind(this, 'vesselsInPort')}
                        >
                          NEARBY FLEET
                        </p>
                        <p
                        className={this.state.toggledTab === 'plannedVessels' ? s.active : null}
                          onClick={this.handleToggleTab.bind(this, 'plannedVessels')}
                        >
                          PLANNED VESSELS
                        </p>
                      </div>
                      {this.state.toggledTab === 'vesselsInPort' &&
                        (!!vesselsNearPort.length ?
                          <VesselsListTab
                            toggledTab={this.state.toggledTab}
                            port={port}
                            handleVesselPositionClick={this.handleVesselPositionClick}
                            vessels={vesselsNearPort}
                          />
                          :
                          <div className={s.no_results}>No results</div>
                        )
                      }
                      {this.state.toggledTab !== 'vesselsInPort' &&
                        (!!vesselsDirectingToPort.length ?
                          <VesselsListTab
                            toggledTab={this.state.toggledTab}
                            port={port}
                            handleVesselPositionClick={this.handleVesselPositionClick}
                            vessels={vesselsDirectingToPort}
                          />
                          :
                          <div className={s.no_results}>No results</div>
                        )
                      }
                    </div>
                    <div className={s.wrapper_planned_cargoes}>
                      <div className={s.wrapper_title}>PLANNED CARGOES</div>
                      {!!port.cargoes.length ?
                        <div className={cx(s.wrapper_table, s.wrapper_cargoes_table)}>
                          <div className={cx(s.general, s.header)}>
                            <div className={s.cargo_name}>CARGO</div>
                            <div className={s.weight}>WEIGHT/VOLUME</div>
                            <div className={s.disharging}>DISCHARGING</div>
                            <div className={s.laycan}>LAYCAN</div>
                          </div>
                          <div className={s.body}>
                            {port.cargoes && port.cargoes.map((item, i) => (
                              <div className={cx(s.general,s.body_part)} key={i}>
                                <div className={s.cargo_name}>
                                  {
                                    item.allowLink ?
                                      generateLinkElement(`/main-deck/general/${item._id}`, item.cargo.join(','))
                                    :
                                      <span className={s.cargo_without_link} title={item.cargo.join(',')}>{item.cargo.join(',')}</span>
                                  }
                                </div>
                                <div className={s.weight}>
                                  <span>
                                    {number(item.totalValues.weight / 1000, '', 'MT')}/
                                    {number(item.totalValues.volume, '', 'CBM')}
                                  </span>
                                </div>
                                <div className={s.disharging}>
                                {!item.unloadingPort.areaParent ?
                                  <a rel="nofollow" title={item.unloadingPort.name.toUpperCase()} href={`/port/${item.unloadingPort._id}`} >{item.unloadingPort.name} </a>
                                  :
                                  <span className={s.name_without_id} title={item.unloadingPort.name}>{item.unloadingPort.name}</span>
                                }
                                </div>
                                <div className={s.laycan}>
                                  <span>
                                    {dateRange(item.readinessDate, item.cancellingDate)}
                                  </span>
                                </div>
                              </div>
                              ))
                            }
                          </div>
                        </div>
                        :
                        <div className={s.no_results}>No results</div>
                      }
                    </div>
                  </div>
                </div>
              }
              {eddie.right.length ?
                eddie.right.map( ({component},i) => {
                return <div className={s.port_container_right_absolute}>
                  {component}
                </div>
              }) : null}
              {this.props.vesselId &&
                  <DialogHalfScreen open>
                    <VesselPreviewNew
                      headerStyles={{ height: "48px", fontSize: "14px" }}
                      monitor
                      shouldScrollInside
                      vesselId={this.props.vesselId}
                      handleClose={this.handleVesselClose}
                      displayCommonHeader
                    />
                  </DialogHalfScreen>
                }
            </div>
          </div>
      </Page>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    ...state.port,
    user: state.login.user,
    topMessages: state.runtime.topMessages,
  }
}

export default connect(mapStateToProps, {openSubscribeDialog, ...portActions})(withStyles(s)((withStyles(p)(withStyles(mp)(
  function (props) {
    return <Eddie><PublicPort {...props} /></Eddie>
  }
)))));


class PortInfoSection extends PureComponent {

  static defaultProps={
    searchEnabled: true,
    editEnabled: true,
    edit: false,
  }

  static contextTypes = {
    store: PropTypes.object,
  };

  searchCounter=0;
  state = { portList: [], page: 1 };
  handleLoadMore = () => {
    this.setState({ page: this.state.page + 1 }, this.handleSearch);

  };

  flattenPorts = (ports) => {
    const flat = [];
    ports.forEach((p) => {
      flat.push(p);
      if (p.sub) {
        p.sub.forEach(sp => flat.push(sp));
      }
    });
    return flat;
  }

  handleSearch = async (query) => {
    this.searchCounter++;
    const reqNum = this.searchCounter;

    if ((!query || query.length < 2)) {
      if (this.state.portList.length) {
        this.setState({ portList: [] });
      }
      return;
    }
    const res = await Port.find({ name: query });
    if (reqNum < this.searchCounter) {
      return;
    }
    this.setState({ portList: this.flattenPorts(res.data) });
  };


  handleResetList = () => {
    //this.handleSearch('');
    this.setState({portList: [], filters: {}, page: 1})
  }

  openComposeEmail = (sp) => {
    const data = { position: 'right' };
    data.to = [sp.emails[0]];
    data.cc = sp.emails.slice(1);
    PubSub.publish('GlobalComposeEmailDialog', data);
  }
  render () {
    const mailGateEnabled = this.context.store?.getState()?.login?.user?.mailGateEnabled;
    const showChatIcon = this.context.store?.getState()?.login?.user;
    const port = this.props.port;
    const activitiesToRender = hardCodeActivities.filter(activity => port.serviceProviders.find(sp => sp.activities.includes(activity)));
    let portSite;
    if (port.site) {
      portSite = port.site.indexOf('http') === 0 ? port.site : "//" + port.site;
    }
    const portRouteName = port?.seo?.sefName || port?._id;
    if (this.props.edit) {
      return <EditPort baseUrl={this.props.baseUrl} portId={port._id} portRouteName={portRouteName} />;
    }
    return (<div className={s.port_info_section} style={{height: '100%'}}>
      <PortHeader
        title={`${port.name} (${port.country.name})`}
        borderRight
        right={
          <div className={s.header_right_wrapper}>
            {!this.props.editPort && this.props.searchEnabled ?
              <SearchInput dropDown handleResetList={this.handleResetList} handleLoadMore={this.handleLoadMore} handleSearch={this.handleSearch} portList={this.state.portList} placeholder={'Enter port or Un Locode'} className={s.search_input}/> : null}
            {this.props.editPort || !this.props.user || !this.props.editEnabled ? null :
              <RaisedButton
                primary
                styles={'xs'}
                className={s.button_edit}
                label={"EDIT INFO / ADD COMPANY"}
                containerElement={<Link className={s.link} saveSearchParams to={`${this.props.baseUrl}${portRouteName}/edit`}/>}
              />
            }
            {
              this.props.portDialog && <OpenInNewTabButton cargo={port} title="full port info" to={`/port/${portRouteName}`}/>
            }
            {this.props.user ?
              <Link className={s.close} to={`${this.props.baseUrl}`} saveSearchParams><CloseIcon/></Link> :
              this.props.handleClose ? <div className={s.close}><CloseIcon  style={{ cursor: "pointer" }} onClick={this.props.handleClose} /></div> : <Link className={s.port_link} to={`${this.props.baseUrl}`} saveSearchParams>Open All Ports</Link>
            }
          </div>
        }
      />
      <div className={s.port_container_body}>
        <div className={s.info_wrapper}>
        { this.props.portDialog && 
          <div className={s.port_dialog_map}>
            <PortTerminals port={port} portDialog={this.props.portDialog} />
          </div>
        }
          <div className={s.info_title}>
            {port.unLoCode &&
            <div className={s.info_title_part}>
              <span>{port.unLoCode}&nbsp;</span>
              <span>UN LOCODE</span>
            </div>
            }
            <div className={s.info_title_part}>
              <span>{port.coordinates[1].toFixed(4)}° / {port.coordinates[0].toFixed(4)}°&nbsp;</span>
              <span>Latitude / Longitude</span>
            </div>
            {
              port.timezone &&
              <div className={s.info_title_part}>
                <span>{port.timezone}</span>
                <span>Time Zone</span>
              </div>
            }
          </div>
          <div className={s.info_container}>
            <h2 className={s.info_container_title}>Port Restrictions</h2>
            <div className={s.harbour_limitation}>
              {port.portLimitations?.maxDraught ? <p>Max draft: {number(port.portLimitations.maxDraught)} </p> : null}
              {port.portLimitations?.maxBeam ? <p>Max beam: {number(port.portLimitations.maxBeam)} </p> : null}
              {port.portLimitations?.maxLOA ? <p>Max LOA: {number(port.portLimitations.maxLOA)} </p> : null}
              {port.portLimitations?.maxDWT ? <p>Max DWT: {number(port.portLimitations.maxDWT)} </p> : null}
              {port.portLimitations?.channelDepth ? <p>Channel Depth: {number(port.portLimitations.channelDepth)} </p> : null}
              {port.portLimitations?.anchorageDepth ? <p>Anchorage Depth: {number(port.portLimitations.anchorageDepth)} </p> : null}
              {port.portLimitations?.meanTide ? <p>Mean Tide: {number(port.portLimitations.meanTide)} </p> : null}
            </div>
          </div>
          <div className={s.info_container}>
            <h2 className={s.info_container_title}>AVAILABLE LIFT AND CRANES</h2>
            <div className={s.available_lift_cranes}>
              <div>
                {Object.keys(port.liftsCranes || {}).sort((a, b) => parseInt(a) - parseInt(b)).map((key) => {
                  if (port.liftsCranes[key] && port.liftsCranes[key].checked) {
                    return (
                      <span key={key} className={s.available}>
                                <ActionCheckCircle /> <span>{key.replace('_', '-')}mt</span>
                              </span>
                    );
                  }
                  return (
                    <span key={key}>
                              <ContentBlock /> <span>{key.replace('_', '-')}mt</span>
                            </span>
                  );
                })}
              </div>
              <div className={s.wrapper_input_mt}>
                {getCranesArray(port).map((item, i) => (
                  <div key={i}>
                    <p>{item.value}mt</p>
                    <p>{item.name}</p>
                  </div>
                ))
                }
              </div>
            </div>
          </div>
          {(port.additionalDescription || port.site) &&
          <div className={s.info_container}>
            <h2 className={s.info_container_title}>General Description</h2>
            <div className={s.description}>
              {port.additionalDescription}
            </div>
            {port.site
              ? <p className={s.site}>Site: <a href={portSite} rel="noopener noreferrer" target="_blank">{port.site}</a></p>
              : null
            }
          </div>
          }
          {!!port.serviceProviders.length &&
          <div className={s.info_container}>
            <h2 className={s.info_container_title}>SERVICE PROVIDERS</h2>
            <div className={s.service_providers}>
              {activitiesToRender.map((activity) => (
                <Collapse defaultOpen={false} title={activity} headerClass={s.collapse_header} titleClass={s.collapse_title}>
                  {port.serviceProviders.filter(sp => sp.activities.includes(activity)).map((item, i) => (
                    <div className={s.service_providers_body} key={item.name + Math.random()} style={{borderBottom: activity === "Stevedoring Company" && item.limitations.length >= 1 ? "none" : ""}}>
                      <div className={s.service_providers_content}>
                        <div className={s.service_providers_part}>
                          <div className={s.provider_title}>{item.name}</div>
                          <div>PIC: {item.PIC.split('http')[0]}</div>
                          <div className={s.link}>
                            <a href={item.PIC.split(item.PIC.split('http')[0])[1]}>{item.PIC.split(item.PIC.split('http')[0])[1]}</a>
                          </div>
                        </div>
                        <div className={s.service_providers_part}>
                          <div>
                            {item.phones.map((phone, i) => (
                              <span key={i} className={s.phone}>
                                          {phone.code + ' ' + phone.phone}{i !== (item.phones.length - 1) && ','}&nbsp;
                                        </span>
                            ))}
                          </div>
                          <div>
                            {item.emails.map((email, i) => (
                              <span key={i}>
                                           <ContactDetailsPopover email={email}>
                                             <a href={`mailto:${email}`} className={s.provider_link}>{email}{i !== (item.emails.length - 1) && ','}</a>
                                           </ContactDetailsPopover>
                                             &nbsp;
                                        </span>
                            ))}<br />
                          </div>
                          <div>{item.address}</div>
                        </div>
                        <div style={{display:'flex',alignItems:'center',gap:'8px'}}>
                          {!showChatIcon ? null : mailGateEnabled ? <span style={{cursor:'pointer',color:'#4380C7',fontSize:'20px'}} onClick={this.openComposeEmail.bind(this, item)} className="material-icons email-icon">email</span> : (
                            <CustomTooltip horizontalAlign='left' verticalAlign='top' tooltip={<div>
                              <div>To use this Service, please connect your mail. Shipnext is a Microsoft Partner solution, and provides an email service that can easily pair with Outlook, Gmail, Sedna and other email services. </div>
                              <Link style={{marginTop:'10px',display:'block'}} to='/settings/connect'>Settings</Link>
                            </div>}>
                              <span style={{cursor:'pointer',color:'#4380C7',fontSize:'20px',opacity:'0.6'}} className="material-icons email-icon">email</span>
                            </CustomTooltip>
                          )}
                        </div>
                      </div>
                      {activity === "Stevedoring Company" && item.limitations.length >= 1 ? (
                        <div className={s.stevedoring_table}>
                          <div className={s.stevedoring_table_header}>
                            {restrictionsService.map(item => <div className={s.stevedoring_table_column}>{item.label}</div>)}
                          </div>
                          {item.limitations.map((lim, index) => (
                            <div className={s.stevedoring_table_row}>
                              {restrictionsService.map(item => <div className={s.stevedoring_table_column}>{lim[item.name]}</div>)}
                            </div>
                          ))}
                        </div>
                      ) : null
                      }
                    </div>
                  ))}
                </Collapse>
              ))
              }
            </div>
          </div>
          }
          {somePropIsTrue(port.facilities) &&
          <div className={s.info_container}>
            <h2 className={s.info_container_title}>AVAILABLE FACILITIES</h2>
            <div className={s.available_facilities}>
              <ul>
                {FACILITIES.map((f) => {
                    if (port.facilities[f.name]) {
                      return <li key={f.name}>{f.label}</li>;
                    }
                    return null;
                  }
                )}
              </ul>
            </div>
          </div>
          }
          {somePropIsTrue(port.warehouses) &&
          <div className={s.info_container}>
            <h2 className={s.info_container_title}>AVAILABLE WAREHOUSES</h2>
            <div className={s.available_warehouse}>
              <ul>
                {ALL_WAREHOUSES.map((f) => {
                    if (port.warehouses[f.name]) {
                      return <li key={f.name}>{f.label}</li>;
                    }
                    return null;
                  }
                )}
              </ul>
            </div>
          </div>
          }
        </div>
      </div>
    </div>)
  }
}

export const PortInfo = withPortDetails(withStyles(s)(PortInfoSection));


